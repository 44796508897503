<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <h2 class="mb-5">Communication with Instructional Staff</h2>

      <p class="mb-3">
        Which of the following rephrased statements about interactions with instructional staff or
        fellow students are found in the reading for this quiz?
      </p>

      <p v-for="option in optionsQ3shuffled" :key="'pt-1-' + option.value" class="mb-0 mt-0">
        <v-checkbox v-model="inputs.question3Values" :value="option.value" class="ml-6 mb-n4 mt-0">
          <template v-slot:label>
            <stemble-latex :content="option.text" />
          </template>
        </v-checkbox>
      </p>
    </v-form>
  </div>
</template>

<script>
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default {
  name: 'UCIrvineIntegrityAgreementQ3',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        question3Values: [],
      },
      optionsQ3: [
        {
          text: "You must follow TA's instructions in lab so that everyone is safe.",
          value: 'followInstructionsAnswer',
        },
        {
          text: 'You will be asked to leave lab if you are using excessive foul language or aggressive behavior.',
          value: 'languageAnswer',
        },
        {
          text: 'Send questions about your grade to genchemlabs@uci.edu.',
          value: 'messageBoardAnswer',
        },
        {
          text: 'Grading issues should always be handled in person with your TA.',
          value: 'gradingIssuesAnswer',
        },
      ],
    };
  },
  computed: {
    seed() {
      return this.$inertia.getSeed();
    },
    optionsQ3shuffled() {
      return seededShuffle(this.optionsQ3, this.seed);
    },
  },
};
</script>
